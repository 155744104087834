 <nav class="navbar navbar-expand-md navbar-light bg fixed-top" *ngIf="showHeader ; else thirdpartylogo"> 
  <div class="container">
    <a class="navbar-brand" href="#">
      <img src="/assets/images/front-page-images/logo grab-eat.jpg" alt="Logo" class="logo mt-3">
    </a>
    <button class="navbar-toggler" type="button" (click)="toggleMenu()" 
            [attr.aria-expanded]="!isMenuCollapsed" aria-controls="navbarContent">
      <div class="menu-icon" [ngClass]="{'open': !isMenuCollapsed}">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </button>
    <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse justify-content-center" id="navbarContent">
      <ul class="navbar-nav mx-auto">
        <li class="nav-item mx-2">
          <a class="nav-link fw-bold" (click)="goTohome(); isMenuCollapsed = true">Home</a>
        </li>
        <li class="nav-item mx-2">
          <a class="nav-link fw-bold" (click)="scrollToHowItWorks(); isMenuCollapsed = true">How It Works</a>
        </li>
        <li class="nav-item mx-2">
          <a class="nav-link fw-bold" (click)="scrollToPricing(); isMenuCollapsed = true">Pricing</a>
        </li>
        <li class="nav-item mx-2">
          <a class="nav-link fw-bold"  (click)="scrollToFaqs(); isMenuCollapsed = true">FAQs</a>
        </li>
      </ul>
      <div class="d-md-flex align-items-center mt-3 mt-md-0 ms-md-3">
        <div *ngIf="user; else loginButtons">
          <button class="btn me-md-2 w-100 w-md-auto" (click)="goToDashboard()">
            <i class="bi bi-person"></i> {{ user?.data?.userName }}
          </button>
        </div>
        <ng-template #loginButtons>
          <div class="col-md-auto px-0">
            <button class="btn me-md-2 w-100 w-md-auto" (click)="goTosignIn(); isMenuCollapsed = true">SIGN IN</button>
          </div>
          <div class="col-md-auto ms-md-2 d-flex mt-3 mt-md-0 ms-auto gap-2">
            <button (click)="goTosignUp(); isMenuCollapsed = true" class="btn btn-dark w-100 w-md-auto">
              TRY FOR FREE <i class="bi bi-arrow-right-short"></i>
            </button>
          </div>
        </ng-template>
      </div>
      
    </div>
  </div>
</nav>
<ng-template #thirdpartylogo>
  <div class="d-flex justify-content-between align-items-center w-100">
    <a class="navbar-brands" href="#">
      <img src="/assets/images/front-page-images/logo grab-eat.jpg" alt="Logo" class="logo m-3">
    </a>
    <div class="mx-4">
      <button *ngIf="email" class="btn email-button">
        {{ email }}
      </button>
    </div>
  </div>
</ng-template>
