import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarService } from '../services/sidebar.service';
import { AuthenticationService } from 'src/app/auth/services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl:'./sidebar.component.html',
  styleUrl: './sidebar.component.scss',
})
export class SidebarComponent {

  activeSubmenu: string="quickAccess"
  isSidebarOpen: boolean;
  sidebarService: any;
constructor(private authService: AuthenticationService,  private router: Router){}

  toggleSidebars() {
    this.sidebarService.toggleSidebar();
  }


  toggleSubmenu(submenu: string) {
    this.activeSubmenu = this.activeSubmenu === submenu ? null : submenu;
  }
  toggleSidebar() {
    this.isSidebarOpen = !this.isSidebarOpen;
  }
  logout() {
    this.authService.logout();
  }
   isActive(route: string): boolean {
    return this.router.url === route;
  }
  
}
