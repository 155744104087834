// import { Injectable, Injector } from '@angular/core';
// import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
// import { TokenService } from './token.service';
// import { Token } from '../models/token';
// import { AuthConfig } from '../auth.config';
// import { AuthenticationService } from './authentication.service';
// import { Observable, Subject } from 'rxjs';

// @Injectable()
// export class JwtHttpInterceptor implements HttpInterceptor {
//   constructor(private config: AuthConfig,
//     private _tokenService: TokenService,
//     private injector: Injector,
//   ) { this.config = new AuthConfig(config); }


//   intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
//     const token: Token = this._tokenService.getToken();
//     const isRepeated = (req as any).isRepeated;
//     req = req.clone({ headers: req.headers.set('client-offset', new Date().getTimezoneOffset().toString()) });

//     if (token) {
//       const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
//       let headers = req.headers.set(this.config.headerName, this.config.headerPrefix + ' ' + token.token);
//       if (isIEOrEdge) {
//         headers = req.headers.set(this.config.headerName, this.config.headerPrefix + ' ' + token.token).set('Cache-Control', 'no-cache')
//           .set('Pragma', 'no-cache')
//           .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
//           .set('If-Modified-Since', '0');
//       };
//       req = req.clone({ headers: headers });
//     }

//     let bindError = req.headers.get('bindErrorHandler');
//     if (req.url.indexOf('token') < 0 && !bindError) {
//       const returnSubject = new Subject<HttpEvent<any>>();
//       next.handle(req).subscribe((event: HttpEvent<any>) => {
//         returnSubject.next(event);
//       },
//         (err: any) => {
//           console.log(err);
//           if (!isRepeated) {
//             if (err instanceof HttpErrorResponse) {
//               if (err.status === 401 || err.status === 403) {
//                 // Circular reference if directly injected
//                 const auth = this.injector.get(AuthenticationService);
//                 auth.logout();
//                 // auth.collectFailedRequest(req, returnSubject);
//                 // auth.refreshToken().pipe(map(result => {
//                 //   auth.retryFailedRequests();
//                 // })).subscribe(a => {
//                 // });
//                 // this.loaderService.hide();

//               }
//               else {
//                 if (err.status === 0) {
//                   // this.loaderService.hide();
//                   // this.toasterService.showError("Coudn't connect to the server or server not responding, Status Code: 0", undefined)
//                 }
//                 else if (err.status === 400) {
//                   // this.loaderService.hide();
//                   // this.toasterService.showError(err.error.message ?? `${err.error?.title}\n ${JSON.stringify(err.error?.errors)}`, undefined)
//                 }
//                 else if (err.status === 500) {
//                   // this.loaderService.hide();
//                   // this.toasterService.showError(err.error, undefined);
//                 }
//                 else {
//                   // this.loaderService.hide();
//                   // this.toasterService.showError("Something went wrong!", undefined);
//                 }
//                 returnSubject.error(err);
//               }
//             }
//           }
//         });
//       return returnSubject;
//     }
//     else {
//       return next.handle(req);
//     }
//   }
// }


import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';
import { Router } from '@angular/router';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService, private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to the api url
        const user = this.authenticationService.userValue;
        const isLoggedIn = user?.data.jwToken;
        const isApiUrl = request.url.startsWith(environment.apiUrl);
        if (isLoggedIn && isApiUrl) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${user.data.jwToken}`
                }
            });
        }

        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    this.authenticationService.logout();
                    sessionStorage.clear();
                    this.router.navigate(['/account/sign-in']); 
                }
                return throwError(error);
            })
        );
    }
    }
