import { environment } from '../../environments/environment';

// SWITHC IT FOR LOCAL DEBUGGING:
export const BASE_URL = environment.apiUrl;
export const BASE_PAGE_SIZE = 10;
export const BASE_PAGE_SIZE_OPTIONS = [10, 25, 50, 100];
export const DATE_FMT = 'MMM dd, yyyy';
export const DATE_TIME_FMT = `${DATE_FMT} hh:mm:ss a`;
export const MIN_EVENT_DURATION = 1000 * 60 * 60;

export enum RegexpPattern {
  youTubeLinkValidators = '^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+$',
  password = '^(?=.*[\\d])(?=.*[a-z])(?=.*[A-Z])(?!.*\\<)(?!.*\\>)(?!.*\\s)(?=.*[\'/\\\\\\\\!"#$%&\'()*+,.\\-/:;=?@[\\]^_`{|}~]).*$',
  email = '^[a-zA-Z0-9_\\.\\+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z-\\.]+$',
  intlPhone = '^\\+(?:[0-9] ?){6,14}[0-9]$',
  // link = '^(ftp|http|https):\/\/[^ "]+$',
  // local = '^(http|https):\/\/localhost(:\d+)?(\/.*)?$',
  mergedRegex = '^(ftp|http|https):\/\/((localhost(:\d+)?)|((?:(?!-)[A-Za-z0-9-]{1,63}(?<!-)\\.)+[A-Za-z]{2,6}))(\/.*)?$',
  customBackHalf = '^[a-zA-Z0-9_-]*$',
  title = '^(?!.*(?:https?|ftp):).*$',
  digits = '^[0-9]*$',
  letters = '^[a-zA-Z ]*$',
  lettersDigits = '^[0-9a-zA-Z\u0621-\u064A]*$',
  enumeration = '^[a-zA-Z;, \u0621-\u064A]*$',
  tenantUrl = '\\.[a-zA-Z0-9-_]+$',
  fullName = '^[a-zA-Z\u0621-\u064A]+[ a-zA-Z\u0621-\u064A]*[a-zA-Z\u0621-\u064A]+$',
  price = "price",
  phonePk = "phonePk",
  // customDomain = '^(([a-zA-Z]|[a-zA-Z][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z]|[A-Za-z][A-Za-z0-9\-]*[A-Za-z0-9])$',
  customDomain = '^((?!-)[A-Za-z0-9-]{1,63}(?<!-)\.)+[A-Za-z]{2,6}$'
}

export enum RoleType {
  SuperAdmin = 0,
  User = 1,

  //company related
  CompanyAdmin = 2,
  CompanyUser = 4
}
export enum SubscriptionStatus 
{
    Unpaid = 0,
    Active = 1,
    Upgraded = 2,
    Renewed = 3,
    Downgraded = 4,
    Cancelled = 5,
    Expired = 6
}
export enum TopupStatus
{
    Pending = 0,
    Approved = 1,
    Failed = 2,
}

export enum TopupType
{
    Credit = 0,
    Debit = 1,
}
// export enum LinkStatus {
//   Active = 0,
//   Disabled = 1,
//   Expired = 2,
//   // Hidden = 3,
//   // Favorite = 4
// }

// export enum ModuleType {
//   Account = 0,
//   Subscription = 1,

//   //feature related
//   Link = 2,
//   QrCode = 3,
//   LinkInBio = 4,
//   LinkAnalytics = 5,
//   Collabration = 6,
//   Support = 7,
//   CustomLinks = 8,
//   Dashboard = 9
// }

// export enum MessageType {
//   Email = 0,
//   Sms = 1
// }

// export enum SslStatus {
//   HaveSsl = 'haveSsl',
//   NoSsl = 'noSsl'
// }

// export enum Section {
//   Dashboard = 'dashboard',
//   Link = 'link',
//   Profile = "Profile",
//   BulkLink = "bulk-link",
//   QRCode = "qr-code",
//   EventLogs = "event-logs",
//   CustomDomain = "custom-domain",
//   BillingHistory = "billing-history"
// }

// export enum CustomDomainStatus {
//   Draft = 0,
//   InVerification = 1,
//   Verified = 2,
// }

// export enum TransactionStatus {
//   Pending = 0,
//   Pending_Approval = 1,
//   Payment_Authorized = 2,
//   Complete = 3,
//   Canceled = 4,
//   Refund = 5
// }

// export enum SubscriptionStatus {
//   Unpaid = 0,
//   Active = 1,
//   Upgraded = 2,
//   Renewed = 3,
//   Downgraded = 4,
//   Cancelled = 5,
//   Expired = 6
// }

// export const transparentSource = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';

// export function externalApiBaseUrl() {
//   return environment.externalApiUrl;
// }

// export function apiBaseUrl() {
//   if (environment.apiUrl == '/') {
//     return `${window.location.origin}/`;
//   }

//   return environment.apiUrl;
// }

// export function scrollTo2(id: string) {
//   let element = document.getElementById(id);
//   if (element) {
//     element.scrollTop = 0;
//   }
// }
