<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  <div class="sidebar bg-dark text-white" [class.open]="sidebarService?.isSidebarOpen$ | async">
    <div class="d-flex flex-column p-3">
      <div class="logo mb-3 text-center">
        <i class="bi bi-house-door"></i> <!-- Use Bootstrap icon for the logo -->
      </div>
      <div class="px-3 py-2">
        <strong>PAGES</strong>
      </div>
      <ul class="nav flex-column bg-black py-4 px-3">
        <li class="nav-item" [class.active-li]="isActive('')">
          <a class="text-white fw-bold d-flex justify-content-between align-items-center py-2"
            (click)="toggleSubmenu('quickAccess')">
            <img src="./../../../assets/images/dashboard/quick-access.svg" alt="quick icon" width="25px">
            Quick Access
            <i class="bi"
              [ngClass]="{'bi-chevron-up': activeSubmenu === 'quickAccess', 'bi-chevron-down': activeSubmenu !== 'quickAccess'}"></i>
          </a>
          <ul class="submenu show" [class.show]="activeSubmenu === 'quickAccess'" >
            <li><a class="nav-link" [routerLink]="['/dashboard']">Home</a></li>
            <li><a class="nav-link" [routerLink]="['/dashboard/topup']">Top up credits</a></li>
            <li><a class="nav-link" [routerLink]="['/dashboard/buy-subscription']">Buy subscription</a></li>
            <li><a class="nav-link just-eat" [routerLink]="['/dashboard/justEatSetting']">JustEat setting</a></li>



          </ul>
        </li>
        <li class="nav-item" [class.active-li]="isActive('')">
          <a class="text-white fw-bold d-flex justify-content-between align-items-center py-2"
            (click)="toggleSubmenu('shiftCatcher')">
            <img src="./../../../assets/images/dashboard/shift-icon.svg" alt="shift icon" width="25px">
            Shift catcher
            <i class="bi"
              [ngClass]="{'bi-chevron-up': activeSubmenu === 'shiftCatcher', 'bi-chevron-down': activeSubmenu !== 'shiftCatcher'}"></i>
          </a>
          <ul class="submenu show" [class.show]="activeSubmenu === 'shiftCatcher'">
            <li><a class="nav-link just-eat" [routerLink]="['/dashboard/topUp-history']">TopUp history</a></li>
            <li><a class="nav-link" [routerLink]="['/dashboard/Subscription-history']">Subscription history</a></li>
            <li><a class="nav-link" [routerLink]="['/dashboard/shift-history']">Shift history</a></li>
          </ul>
        </li>
        
        <li class="nav-item"[class.active-li]="isActive('')">
          <a class="text-white fw-bold d-flex justify-content-between align-items-center py-2"
            (click)="toggleSubmenu('courerdetail')">
            <img src="./../../../assets/images/dashboard/my-acccount.svg" alt="quick icon" width="25px">
            Courier Detail
            <i class="bi"
              [ngClass]="{'bi-chevron-up': activeSubmenu === 'courerdetail', 'bi-chevron-down': activeSubmenu !== 'courerdetail'}"></i>
          </a>
          <ul class="submenu collapse show" [class.show]="activeSubmenu === 'courerdetail'">
            <li><a class="nav-link" [routerLink]="['/dashboard/schedule']">Time schedule</a></li>
            <li><a class="nav-link" [routerLink]="['/dashboard/zones']">Zone picker</a></li>
            <li><a class="nav-link just-eat" [routerLink]="['/dashboard/justEat-login']">JustEat login</a></li>
          </ul>
        </li>
        <li class="nav-item"[class.active-li]="isActive('')">
          <a class="text-white fw-bold d-flex justify-content-between align-items-center py-2"
            (click)="toggleSubmenu('account')">
            <img src="./../../../assets/images/dashboard/my-acccount.svg" alt="quick icon" width="25px">
            My Account
            <i class="bi"
              [ngClass]="{'bi-chevron-up': activeSubmenu === 'account', 'bi-chevron-down': activeSubmenu !== 'account'}"></i>
          </a>
          <ul class="submenu collapse show" [class.show]="activeSubmenu === 'account'">
            <li><a class="nav-link" [routerLink]="['/dashboard/change-password']">Change Password</a></li>
            <li><a class="nav-link" [routerLink]="['/dashboard/changenumber']">Change Phone Number</a></li>
            <li><a class="nav-link" [routerLink]="['/dashboard/contactUs']">Contact Us</a></li>
            <li><a class="nav-link just-eat" [routerLink]="['/dashboard/aboutUs']">About us</a></li>
            <li><a class="nav-link just-eat" [routerLink]="['/dashboard/deleteProfile']">Delete account</a></li>
            <li><a class="nav-link" (click)="logout()">Sign Out</a></li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</nav>